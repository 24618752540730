<template>
    <div class="row">
        <div class="col-xs-12">
            <div class="form-group my-3">
             <div class="form-check form-check-inline">
                <input class="form-check-input" type="radio" name="phonenumber" id="phonenumber" value="phonenumber">
                <label class="form-check-label" for="phonenumber">Mobile number</label>
              </div>
              <div class="form-check form-check-inline">
                <input class="form-check-input" type="radio" name="email" id="email" value="email">
                <label class="form-check-label" for="email">Email</label>
              </div>
            </div>
            <div class="form-group my-3">
               <textarea class="form-control" name="message" id="message" rows="3"></textarea>
            </div>
            <div class="text-center my-4">
                <button type="button" :id="'btn-'+title" class="btn btn-color text-white">Send Invitation</button>
            </div>
              
        </div>
    </div>
</template>
<script>
export default {
    props:["title","username"],    
}
</script>

