<template>
    <div class="page-content w-100">
       <!-- start heading -->
   <div class="row">
  <div class="col-sm-12">
     <p class="text-center page-heading">Invite</p>
   </div>

 </div>
    <!-- end heading -->
  <div class="row mt-5">
  <div class="col-sm-12 mx-auto ml-2" style="margin-bottom:30px;">

      <!-- start accordion -->
         <div class="accordion box-shadow" id="accordionInvite">
  <div class="accordion-item">
    <h2 class="accordion-header" id="headingOne">
      <button class="accordion-button text-color border-color" type="button" data-bs-toggle="collapse" data-bs-target="#collapseDoctors" aria-expanded="true" aria-controls="collapseDoctors">
        <strong>Doctors</strong>
      </button>
    </h2>
    <div id="collapseDoctors" class="accordion-collapse collapse show" aria-labelledby="headingOne" data-bs-parent="#accordionInvite">
      <div class="accordion-body p-4">
          <Invite title="doctors"></Invite>
      </div>
    </div>
  </div>
  <!-- end first -->
  <div class="accordion-item">
    <h2 class="accordion-header" id="headingTwo">
      <button class="accordion-button collapsed text-color border-color" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
       <strong>Others</strong>
      </button>
    </h2>
    <div id="collapseTwo" class="accordion-collapse collapse" aria-labelledby="headingTwo" data-bs-parent="#accordionInvite">
      <div class="accordion-body p-4">
         <Invite title="others"></Invite>
      </div>
    </div>
  </div>
  <!-- end second -->
</div>

      <!-- end accordion -->

  </div>
  </div>
 </div>

</template>

<script>
import Invite from "./invitelayout.vue"
export default {
   components:{
     Invite
   }
}
</script>